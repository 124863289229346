import { ImageField, LinkField } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import ImageWrapper, { resizeHeight } from 'components/helpers/ImageWrapper/ImageWrapper';
import NextLink from 'next/link';

export type HeaderLogoProps = {
  fields: {
    logo: ImageField;
    LogoLink: LinkField;
  };
};

const HeaderLogo = ({ fields }: HeaderLogoProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }

  const logoUrl = fields.LogoLink?.value?.href || '/';
  return (
    <div id="header-logo">
      <NextLink href={logoUrl}>
        <a
          href={logoUrl}
          aria-label={fields.LogoLink.value?.text}
          title={fields.LogoLink.value?.title}
        >
          <span className={classNames('inline-block', 'px-5', 'md:hidden')}>
            <ImageWrapper
              priority
              skipExternalSvg
              imageClass={classNames('h-fill', 'w-fill')}
              editable={false}
              field={resizeHeight(fields.logo, 36)}
            />
          </span>
          <span className={classNames('hidden', 'md:inline-block')}>
            <ImageWrapper
              priority
              skipExternalSvg
              imageClass={classNames('h-fill', 'w-fill')}
              editable={false}
              field={resizeHeight(fields.logo, 40)}
            />
          </span>
        </a>
      </NextLink>
    </div>
  );
};

export default HeaderLogo;
