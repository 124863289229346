// Global
import classNames from 'classnames';

// Lib
import { getEnumValueOverRide } from 'lib/get-enum';

// Generated
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';

// Local
import Heading from 'components/helpers/Heading/Heading';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import CtaGroup, { CtaAlignment } from '../CtaGroup/CtaGroup';

interface ExtendedCardProps {
  border?: boolean;
  alignment?: string;
}

export type IconCardProps = Sitecore.Override.ItemEx &
  Feature.Data.Cards.Fields.CardWithIcon &
  ExtendedCardProps;

const IconCard = ({ fields, border, alignment }: IconCardProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) return <></>;

  const contentAlign = alignment?.toLowerCase();

  return (
    <div
      className={classNames(
        'flex',
        'flex-col',
        'h-full',
        'w-full',
        'rounded-lg',
        'bg-white',
        'shadow-card',
        {
          'border-blue': border,
          'border-t-8': border,
          'text-center': contentAlign === 'center',
        }
      )}
    >
      <div
        className={classNames(
          'border-b',
          'border-gray',
          'border-l',
          'border-r',
          'flex',
          'flex-col',
          'flex-grow',
          'p-5',
          'md:p-8',
          'rounded-b-lg',
          'shrink-0',
          'text-gray-dark',
          {
            'border-t': !border,
            'rounded-t-lg': !border,
            'items-center': contentAlign === 'center',
          }
        )}
      >
        <span
          className={classNames('mb-6', 'text-teal', 'w-fit', {
            'mx-auto': contentAlign === 'center',
          })}
        >
          <ImageWrapper
            field={
              {
                ...fields.iconImage,
                value: {
                  ...fields.iconImage.value,
                  width: fields.iconImage.value?.width || '60px',
                  height: fields.iconImage.value?.height || '60px',
                },
              } as SizedImageField
            }
          />
        </span>
        <Heading
          headingText={fields?.headline}
          headingClassName={classNames('mb-2', 'font-regular')}
          headingLevel="h3"
          headingSize="sm"
          description={fields?.description}
          descriptionClassName={classNames('mb-8', 'text-base')}
          eyebrow={fields?.eyebrowText}
        />
        <div className={classNames('mt-auto')}>
          <CtaGroup
            {...fields}
            ctaAlignment={getEnumValueOverRide<CtaAlignment>(`${contentAlign as CtaAlignment}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default IconCard;
