// Global
// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import Heading from 'components/helpers/Heading/Heading';
import { getEnumValueOverRide } from 'lib/get-enum';
import CtaGroup, { CtaAlignment } from '../CtaGroup/CtaGroup';
import { getThemeClasses, Theme } from 'lib/get-theme';

interface ExtendedCardProps {
  border?: boolean;
  alignment?: string;
  theme: Theme | undefined;
}

export type GeneralCardProps = Sitecore.Override.ItemEx &
  Feature.Data.Cards.Fields.GeneralCard &
  ExtendedCardProps;

export type themeValue =
  | 'theme-white'
  | 'theme-blue'
  | 'theme-blue--light'
  | 'theme-blue--navy'
  | 'theme-blue--peacock'
  | 'theme-split--blue-white'
  | 'theme-gray'
  | 'theme-inherit'
  | 'theme-prosano-blue'
  | 'theme-prosano-white'
  | 'theme-prosano-blue--navy'
  | 'theme-prosano-blue--light';

const GeneralCard = ({ fields, border, alignment, theme }: GeneralCardProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }

  const contentAlign = alignment?.toLowerCase();

  return (
    <div
      className={classNames(
        'flex',
        'flex-col',
        'h-full',
        'w-full',
        'rounded-lg',
        getThemeClasses(theme),
        'shadow-card',
        {
          'border-blue': border,
          'text-center': contentAlign === 'center',
        }
      )}
    >
      <div
        className={classNames(
          'border-gray',
          'flex',
          'flex-col',
          'flex-grow',
          'p-5',
          'md:p-8',
          'rounded-b-lg',
          'shrink-0',
          'text-gray-dark',
          {
            'rounded-t-lg': !border,
          },
          getThemeClasses(theme),
          '!text-theme-text'
        )}
      >
        <Heading
          headingText={fields?.headline}
          headingClassName={classNames('mb-2', 'font-regular', getThemeClasses(theme))}
          headingLevel="h3"
          headingSize="sm"
          description={fields?.description}
          descriptionClassName={classNames('mb-8', 'text-base')}
          eyebrow={fields?.eyebrowText}
        />
        <div className={classNames('mt-auto')}>
          <CtaGroup
            {...fields}
            ctaAlignment={getEnumValueOverRide<CtaAlignment>(`${contentAlign as CtaAlignment}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralCard;
