// Global
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import CTA, { ButtonProps } from '../../Button/Button';
import Modal from 'react-bootstrap/Modal';
import HeroIcon from 'components/helpers/Icons/HeroIcon/HeroIcon';

export type ModalProps = {
  backdrop?: 'static' | true | false;
  children: React.ReactNode | React.ReactNode[];
  externalTrigger?: boolean;
  handleModal?: (value: React.SetStateAction<boolean>) => void;
  showModal?: boolean;
  showCloseButton?: boolean;
  title?: string;
  triggerStyles?: ButtonProps['variant'];
  triggerText?: string;
  theme?: string;
};

const ModalStandard = ({
  backdrop,
  children,
  externalTrigger,
  title,
  triggerStyles,
  triggerText,
  showModal = false,
  showCloseButton = true,
  handleModal,
  theme,
}: ModalProps) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  useEffect(() => {
    showModal !== show && setShow(showModal);
  }, [showModal]);

  useEffect(() => {
    handleModal && handleModal(show);
  }, [show]);

  return (
    <>
      {!externalTrigger && (
        <CTA text={triggerText} variant={triggerStyles} onClick={() => handleShow()} />
      )}
      <Modal
        show={show}
        backdrop={backdrop}
        onHide={handleClose}
        className={classNames(
          'bg-gray-darkest',
          'bg-opacity-80',
          'fixed',
          'min-h-screen',
          'h-full',
          'hidden',
          'left-0',
          'outline-none',
          'overflow-x-hidden',
          'overflow-y-auto',
          'top-0',
          'transition-opacity',
          'w-full',
          'z-40',
          theme
        )}
        dialogClassName={classNames(
          'flex',
          'h-full',
          'pointer-events-none',
          'relative',
          'm-auto',
          'items-center',
          'justify-center',
          'md:pt-0',
          'md:w-3/4',
          'pt-9',
          'w-11/12'
        )}
        contentClassName={classNames(
          'bg-clip-padding',
          'bg-white',
          'border-none',
          'flex',
          'flex-col',
          'max-h-[75%]',
          'max-w-3xl',
          'outline-none',
          'overflow-visible',
          'pointer-events-auto',
          'relative',
          'rounded-md',
          'shadow-lg',
          'text-current',
          'w-full'
        )}
        data-component="helpers/modals/modal-standard"
      >
        {title && (
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body
          className={classNames(
            'bg-white',
            'md:h-full',
            'md:px-8',
            'lg:px-10',
            'overflow-y-auto',
            'px-4',
            'py-8',
            'rounded-lg',
            'sm:px-6',
            'sm:py-12',
            {
              'lg:pb-10': showCloseButton,
              'lg:pt-20': showCloseButton,
              'lg:py-10': !showCloseButton,
              'md:pb-8': showCloseButton,
              'md:pt-16': showCloseButton,
              'md:py-8': !showCloseButton,
            }
          )}
        >
          <div data-coveo-no-index="true" className={classNames('w-full h-full')}>
            {children}
          </div>
        </Modal.Body>
        {showCloseButton ? (
          <button
            className={classNames(
              'absolute',
              'right-1',
              '-top-6 sm:-top-9',
              'md:right-5',
              'md:top-5',
              'z-50',
              'text-white',
              'md:text-blue-navy',
              'transition-opacity'
            )}
            onClick={() => handleClose()}
            aria-label="Close"
          >
            <HeroIcon
              icon={'XMarkIcon'}
              className={classNames('w-4 h-4 mb-2 sm:mb-0 sm:w-8 sm:h-8')}
              size={'sm'}
              outline
            />
          </button>
        ) : null}
      </Modal>
    </>
  );
};

export default ModalStandard;
