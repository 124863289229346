// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import classNames from 'classnames';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import VideoItem from 'components/helpers/VideoItem/VideoItem';
import { theme } from 'tailwind.base.config';

export type MediaComponentProps = Feature.Components.General.Fields.MediaComponent;

const MediaComponent = ({ fields }: MediaComponentProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }

  const isVideo = fields?.youtubeUrl?.value || fields?.vimeoUrl?.value;

  return (
    <div data-component="authorable/general/mediacomponent" className={classNames('w-full')}>
      {fields.paddingForDesktopImage?.value && (
        <style scoped>
          {`
          .media-custom-padding{
            padding-left: 0;
            padding-right: 0;
          }
          @media screen and (min-width: ${theme.screens.md}){
            .media-custom-padding{
              padding-left: ${fields.paddingForDesktopImage?.value};
              padding-right: ${fields.paddingForDesktopImage?.value};
            }
          }
        `}
        </style>
      )}
      <div
        className={classNames('mx-auto', {
          'media-custom-padding': fields.paddingForDesktopImage?.value,
        })}
      >
        {!isVideo ? (
          <>
            <span className={classNames('hidden', 'md:block')}>
              <ImageWrapper
                field={fields.desktopImage as SizedImageField}
                layout="responsive"
                imageClass={classNames('hidden', 'md:block', 'mx-0')}
              />
            </span>
            <span className={classNames('hidden', 'sm:max-md:block')}>
              <ImageWrapper
                field={
                  (fields.tabletImage?.value?.src
                    ? fields.tabletImage
                    : fields.desktopImage) as SizedImageField
                }
                layout="responsive"
                imageClass={classNames('mx-auto')}
              />
            </span>
            <span className={classNames('block', 'sm:hidden')}>
              <ImageWrapper
                field={
                  (fields.mobileImage?.value?.src
                    ? fields.mobileImage
                    : fields.tabletImage?.value?.src
                    ? fields.tabletImage
                    : !fields.tabletImage?.value?.src
                    ? fields.desktopImage
                    : fields.desktopImage) as SizedImageField
                }
                layout="responsive"
                imageClass={classNames('mx-auto')}
              />
            </span>
          </>
        ) : (
          <VideoItem
            title={fields.videoTitle?.value}
            videoSrc={fields?.youtubeUrl.value ? fields?.youtubeUrl.value : fields?.vimeoUrl.value}
          />
        )}
      </div>
    </div>
  );
};

export default MediaComponent;
