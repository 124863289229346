/* eslint-disable @next/next/no-before-interactive-script-outside-document */
/* eslint-disable @next/next/inline-script-id */
// Components
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { DangerousHtml } from 'components/helpers/DangerousHtml/DangerousHtml';
import IsDevelopmentOrLower from 'components/helpers/Utility/IsDevelopmentOrLower/IsDevelopmentOrLower';

const AZBlueScript = (): JSX.Element => {
  const context = useSitecoreContext();
  const azblueLayoutScript = context?.sitecoreContext?.azblueLayoutScript;

  if (IsDevelopmentOrLower()) {
    console.log('AZBlueScriptCheck', azblueLayoutScript);
  }

  const componentAnchorId = 'azblue-pagelayout-script';

  // Fail out if we don't have any fields
  if (!azblueLayoutScript?.script) {
    return <></>;
  }

  return (
    <div id={componentAnchorId}>
      {azblueLayoutScript.useAdvancedScriptLoader ? (
        <DangerousHtml html={azblueLayoutScript?.script} />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: `
      ${azblueLayoutScript?.script}
      `,
          }}
        />
      )}
    </div>
  );
};

export default AZBlueScript;
