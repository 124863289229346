// Global

// Local
import Xup, { ColumnOptions } from 'components/helpers/Xup/Xup';
import PlanCard, { PlanCardProps } from 'components/helpers/cards/PlanCard';
import classNames from 'classnames';
import { Feature } from '.generated/templates/Feature.Items.model';
import { getEnum } from 'lib/get-enum';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';

export type PlanCardListingProps = Sitecore.Override.ComponentBase &
  Feature.Components.Cards.Fields.ImageCardListing;

const PlanCardListing = ({ fields, rendering }: PlanCardListingProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  const componentAnchorId = rendering?.uid;
  const columns = getEnum<ColumnOptions>(fields.numberPerRow);

  const renderList = () =>
    fields?.selectedCards.map((i) => {
      const card = i as PlanCardProps;
      return (
        <li
          className={classNames(
            'flex',
            'flex-col',
            'flex-grow',
            'h-auto',
            'h-full',
            'items-center',
            'mb-5',
            'md:mb-0',
            'shrink-0'
          )}
          key={card.id}
        >
          <PlanCard {...card} alignment={getEnum(fields.cardTextAlignment)} />
        </li>
      );
    });
  return (
    <div
      data-component="authorable/card-listings/plan-card-listing"
      id={componentAnchorId}
      className={classNames('anchorIdIndentifier', 'plan-cards')}
    >
      <EditingHelpText
        condition={!fields.selectedCards.length}
        text="Plan Card Listing | No Cards Selected. "
      />
      <Xup columns={columns}>{renderList()}</Xup>
    </div>
  );
};

export default PlanCardListing;
