// Global
// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import Heading from 'components/helpers/Heading/Heading';
import { HeroIconNames } from '../Icons/HeroIcon/HeroIcon';
import { getEnum, getEnumValueOverRide } from 'lib/get-enum';
import React from 'react';
import ContentMangedIcon from '../Icons/ContentMangedIcon/ContentMangedIcon';
import CtaGroup, { CtaAlignment } from '../CtaGroup/CtaGroup';

interface ExtendedCardProps {
  alignment?: string;
}

export type PlanCardProps = Sitecore.Override.ItemEx &
  Feature.Data.Cards.Fields.PlanCard &
  ExtendedCardProps;

export type PlanCardDetail = Sitecore.Override.ItemEx & Feature.Data.Cards.Fields.PlanCardDetails;

const PlanCard = ({ fields, alignment }: PlanCardProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }
  const contentAlign = alignment?.toLowerCase();

  const renderDetailList = fields?.selectedDetails.map((i) => {
    const card = i as PlanCardDetail;
    const listIcon = getEnum<HeroIconNames>(card?.fields.icon);
    return (
      <React.Fragment key={card.id}>
        {card?.fields?.detail.value && (
          <li className={classNames('flex', 'items-start', 'mb-3')}>
            <div className={classNames('text-xl', 'text-teal')}>
              <ContentMangedIcon icon={!listIcon ? 'CheckIcon' : listIcon} size={'em'} />
            </div>
            <div className={classNames('plan-card-detail', 'ml-2', 'text-base', 'text-gray-dark')}>
              {card?.fields?.detail.value}
            </div>
          </li>
        )}
      </React.Fragment>
    );
  });

  return (
    <div
      className={classNames(
        'flex',
        'flex-col',
        'h-full',
        'w-full',
        'rounded-lg',
        'bg-white',
        'border-blue',
        'border-t-8',
        'shadow-card',
        { 'text-center': contentAlign === 'center' }
      )}
    >
      <div
        className={classNames(
          'border-b',
          'border-gray',
          'border-l',
          'border-r',
          'flex',
          'flex-col',
          'flex-grow',
          'p-6',
          'rounded-b-lg',
          'shrink-0',
          'text-gray-darkest',
          { 'items-center': contentAlign === 'center' }
        )}
      >
        <Heading
          headingText={fields?.headline}
          headingClassName={classNames('mb-2', 'mt-1', 'font-regular')}
          headingLevel="h3"
          headingSize="sm"
          description={fields?.description}
          descriptionClassName={classNames('mb-6', 'text-lg', 'italic')}
          eyebrow={fields?.tagline}
          eyebrowClassName={classNames(
            'bg-blue-sky',
            'font-bolder',
            'max-w-max',
            'mb-4',
            'px-2',
            'py-0.5',
            'rounded-full',
            'text-blue-navy',
            'text-xs'
          )}
        />
        <ul className={classNames('mb-12', 'last:mb-0')}>{renderDetailList}</ul>
        <div className={classNames('mt-auto')}>
          <CtaGroup
            {...fields}
            ctaAlignment={getEnumValueOverRide<CtaAlignment>(`${contentAlign as CtaAlignment}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
