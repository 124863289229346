// Global
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

// Lib

// Local
import Button from 'components/helpers/Button/Button';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import { Theme, getThemeClasses } from 'lib/get-theme';
import GlobalHeaderSearchBar from 'components/authorable/search/GlobalHeaderSearchBar/GlobalHeaderSearchBar';
import MobileGlobalHeaderSearchBar from 'components/authorable/search/GlobalHeaderSearchBar/MobileGlobalHeaderSearchBar';
import { useClickAway } from 'lib/utils/component-util';
import { Feature } from '.generated/templates/Feature.Items.model';
import { useRouter } from 'next/router';
import {
  MainNavigationMenu,
  NavigationMenuLink,
  UtilityDropDownMenu,
} from 'components/helpers/Header/header-types';
import MainNavGlobal from 'components/helpers/Header/MainNavSection/MainNavGlobal';
import HeaderUtilityDropDownMenu from 'components/helpers/Header/UtilityNavSection/HeaderUtilityDropDownMenu';
import HeaderLoginCta from 'components/helpers/Header/MainNavSection/HeaderLoginCta';
import SecondaryCta from 'components/helpers/Header/MainNavSection/SecondaryCta';
import TertiaryCta from 'components/helpers/Header/MainNavSection/TertiaryCta';
//import HeaderLanguageToggle from 'components/helpers/Header/HeaderButtons/HeaderLanguageToggle';
import HeaderLogo from 'components/helpers/Header/MainNavSection/HeaderLogo';
import HeaderPhone from 'components/helpers/Header/HeaderButtons/HeaderPhone';
import HeaderHamburger from 'components/helpers/Header/HeaderButtons/HeaderHamburger';
import ConditionalRender from 'components/helpers/ConditionalWrapper/ConditionalRender';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import LinkA11yWrapper from 'components/helpers/LinkA11yWrapper/LinkA11yWrapper';
import LanguageSwitcher from 'components/helpers/LanguageSwitcher/LanguageSwitcher';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { getEnum } from 'lib/get-enum';

export type HeaderProps = Feature.Components.Header.Fields.Header & {
  fields?: {
    utilityNavMenus: UtilityDropDownMenu[];
    mainNavSections: MainNavigationMenu[];
    loginCta: NavigationMenuLink[];
    secondaryCta: NavigationMenuLink[];
    tertiaryCta: NavigationMenuLink[];
    secondaryCtaText: Field<string>;
    tertiaryCtaText: Field<string>;
    locales: Sitecore.Override.ItemEx[];
    enableLanguageSwitcher: Field<boolean>;
  };
};

function Header({ fields }: HeaderProps): JSX.Element {
  const sitecoreContext = useSitecoreContext();
  const themeValue = getEnum<Theme>(fields?.backgroundStyle);
  const menuTheme = getEnum<Theme>(fields?.menuStyle);

  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const [activeSecondaryNavItem, setActiveSecondaryNavItem] = useState('');

  const mobileSearchButtonRef = useRef(null);
  const mobileHeaderSearchRef = useRef(null);
  const mobileInput = useRef<HTMLInputElement>(null);

  const phoneNumber =
    sitecoreContext?.sitecoreContext?.phoneNumber?.fields?.phoneNumber?.value ||
    fields?.telephoneText?.value;
  const phoneNumberTTY = sitecoreContext?.sitecoreContext?.phoneNumber?.fields?.tty?.value;

  const route = useRouter();

  const onMobileClickAway = () => {
    setIsMobileSearchOpen(false);
  };

  useClickAway([mobileSearchButtonRef, mobileHeaderSearchRef], onMobileClickAway);

  const onClickHamburgerButton = () => {
    const activePrimaryNavItemSubnav = document.querySelector('#navigation-primary button.isActive')
      ?.nextSibling as HTMLElement;
    const activeSecondaryNavItemSubnav = document.querySelector(
      '#navigation-secondary button.isActive'
    )?.nextSibling as HTMLElement;
    const body = document.querySelector('body') as HTMLElement;

    if (activePrimaryNavItemSubnav) activePrimaryNavItemSubnav.style.maxHeight = '';
    if (activeSecondaryNavItemSubnav) activeSecondaryNavItemSubnav.style.maxHeight = '';

    body.style.overflow = 'initial';
    setActiveSecondaryNavItem('');
    setIsNavOpen(!isNavOpen);
    setIsMobileSearchOpen(false);
  };

  const onClickSearchButton = () => {
    setIsNavOpen(false);
    setIsMobileSearchOpen(!isMobileSearchOpen);
  };

  useEffect(() => {
    if (isMobileSearchOpen) {
      mobileInput.current?.focus();
    } else {
      mobileInput.current?.blur();
    }
  }, [isMobileSearchOpen]);

  useEffect(() => {
    isNavOpen && onClickHamburgerButton();
  }, [route?.asPath]);

  return (
    <>
      <div>
        <div
          className={classNames(
            'before:header-component',
            {
              'before:absolute': !fields.hideUtility?.value,
              'before:bg-blue-navy': !fields.hideUtility?.value,
              'before:content-[""]': !fields.hideUtility?.value,
              'before:block': !fields.hideUtility?.value,
              'before:h-12': !fields.hideUtility?.value,
              'before:left-0': !fields.hideUtility?.value,
              'before:top-0': !fields.hideUtility?.value,
              'before:w-full': !fields.hideUtility?.value,
              'pt-12': !fields.hideUtility?.value,
            },

            'relative',
            'md:flex'
          )}
          data-component="authorable/site/header"
        >
          {/*  Mobile version for Phone Number and  Login CTA */}
          <div
            className={classNames(
              'flex',
              'md:hidden',
              'absolute',
              'bottom-[45%]',
              'items-center',
              'justify-between',
              'w-full',
              '-translate-y-1/4',
              'px-5',
              'h-12',
              themeValue,
              'bg-theme-bg',
              'text-theme-text',
              'z-10'
            )}
          >
            <ConditionalRender condition={!!phoneNumber}>
              <HeaderPhone
                variant="mobile"
                phoneNumber={phoneNumber}
                phoneNumberTTY={phoneNumberTTY}
              />
            </ConditionalRender>
            <HeaderLoginCta fields={fields} variant="mobile" />
          </div>

          <div id="header-menubar" className={classNames('md:px-5', getThemeClasses(menuTheme))}>
            <HeaderLogo fields={fields} />
            {/*  Search Button for Mobile Version */}

            <span ref={mobileSearchButtonRef}>
              <Button
                icon="MagnifyingGlassIcon"
                iconPosition="center"
                id="header-button-search"
                size="small"
                srOnlyText="Search"
                text=""
                type="button"
                variant="link"
                className={classNames([isMobileSearchOpen && 'hidden'])}
                onClick={onClickSearchButton}
              />
            </span>

            {/*  Nav/Hamburger Button for Mobile Version  */}
            <HeaderHamburger onClick={() => onClickHamburgerButton()} isNavOpen={isNavOpen} />

            {/*  Search Flyout for Mobile Version  */}
            <div
              className={classNames(
                'bg-white',
                'md:!hidden',
                'flex',
                'flex-col',
                'absolute',
                'top-[112px]',
                'transition-transform',
                'w-full',
                '-z-10',
                // search blackout
                'after:transition-opacity',
                'after:delay-75',
                'after:h-screen',
                'after:bg-black',
                'after:w-full',
                'after:absolute',
                'after:left-0',
                'after:right-0',
                'after:top-full',
                'after:z-50',
                {
                  isOpen: !isNavOpen && isMobileSearchOpen,
                  'after:opacity-0': !isMobileSearchOpen,
                  'after:opacity-60': isMobileSearchOpen,
                  '-translate-y-full': !isMobileSearchOpen,
                  'translate-y-0': isMobileSearchOpen,
                  'overflow-hidden': !isMobileSearchOpen,
                }
              )}
              id="header-search-flyout"
            >
              <nav
                className="header-search-navList"
                id="mobile-search-nav"
                role="search"
                ref={mobileHeaderSearchRef}
              >
                <MobileGlobalHeaderSearchBar
                  fields={fields}
                  ref={mobileInput}
                  callBack={onClickSearchButton}
                />
              </nav>
            </div>

            <div
              className={classNames('px-5', 'md:px-0', {
                isOpen: isNavOpen,
                isSlidOpen: activeSecondaryNavItem.length,
              })}
              id="header-flyout"
            >
              {/*  Main Navigation  */}
              <MainNavGlobal fields={fields} />

              {/*  Utility Navigation  */}
              {!fields.hideUtility?.value && (
                <>
                  <nav
                    className={classNames(
                      'header-navList',
                      'md:px-12',
                      'md:flex',
                      'md:items-center'
                    )}
                    id="navigation-secondary"
                    role="navigation"
                  >
                    <ul
                      className={classNames(
                        'justify-start',
                        'md:gap-9',
                        'md:flex',
                        'md:items-center',
                        'w-full'
                      )}
                    >
                      {/* renders in both mobile and desktop  */}
                      {fields?.utilityNavMenus?.map((navItem: UtilityDropDownMenu) => (
                        <li
                          key={navItem.id}
                          className={classNames('overflow-visible', 'relative', {
                            'md:hidden': navItem?.fields?.displayOnMobileOnly?.value,
                          })}
                        >
                          {navItem?.fields?.isStaticLink?.value ? (
                            <LinkA11yWrapper
                              className={classNames(
                                'font-semibold',
                                'header-navList-menuItem',
                                'hover:bg-btn-bg-secondary-hover',
                                'hover:text-btn-text-secondary-hover',
                                'text-btn-text-secondary',
                                'px-8',
                                'py-3'
                              )}
                              field={{
                                href: navItem?.fields?.Link?.value?.href,
                                text: navItem?.fields?.heading?.value,
                                linktype: navItem?.fields?.Link?.value?.type,
                                target: navItem?.fields?.Link?.value?.target,
                              }}
                            />
                          ) : (
                            <HeaderUtilityDropDownMenu item={navItem} />
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                  {/* Mobile language toggle */}
                  <nav className={classNames('py-3', 'md:hidden')} role="navigation">
                    <ul
                      className={classNames(
                        'justify-start',
                        'md:gap-9',
                        'md:flex',
                        'md:items-center',
                        'w-full'
                      )}
                    >
                      <ConditionalRender condition={fields?.enableLanguageSwitcher?.value}>
                        <li className={classNames('py-3')}>
                          <LanguageSwitcher
                            locales={fields?.locales}
                            enabled={fields?.enableLanguageSwitcher}
                            variant="mobile"
                          />
                        </li>
                      </ConditionalRender>
                    </ul>
                  </nav>
                  <nav
                    className={classNames('header-navList', 'md:px-12', 'min-w-[680px]')}
                    id="navigation-tertiary"
                    role="navigation"
                  >
                    <ul
                      className={classNames(
                        'justify-end',
                        'md:gap-8',
                        'md:flex',
                        'md:items-center',
                        'w-full'
                      )}
                    >
                      {/*  Search Bar  Destktop Version  */}

                      <li className={classNames('hidden', 'md:block', 'min-w-[300px]')}>
                        <GlobalHeaderSearchBar fields={fields} />
                      </li>

                      {/*  Phone Number Destktop Version  */}
                      <ConditionalRender condition={!!phoneNumber}>
                        <li className={classNames('hidden', 'md:list-item')}>
                          <HeaderPhone
                            variant="desktop"
                            phoneNumber={phoneNumber}
                            phoneNumberTTY={phoneNumberTTY}
                          />
                        </li>
                      </ConditionalRender>

                      <ConditionalRender condition={fields?.enableLanguageSwitcher?.value}>
                        <li className={classNames('hidden', 'md:block')}>
                          <LanguageSwitcher
                            locales={fields?.locales}
                            enabled={fields?.enableLanguageSwitcher}
                            variant="desktop"
                          />
                        </li>
                      </ConditionalRender>
                    </ul>
                  </nav>
                </>
              )}
              <nav id="navigation-footer" role="navigation" className={classNames('flex', 'gap-4')}>
                {/*  payBill/secondary CTA  */}
                <Button field={fields?.payBillCta} size="small" variant="secondary" />
                {/*  Tertiary CTA for Desktop Version  */}
                <TertiaryCta fields={fields} variant="desktop" />
                {/*  Secondary CTA for Desktop Version  */}
                <SecondaryCta fields={fields} variant="desktop" />
                {/*  Login CTA for Desktop Version  */}
                <HeaderLoginCta fields={fields} variant="desktop" />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
